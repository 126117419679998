export default defineNuxtRouteMiddleware( async( to, from ) => {
    const user = useSupabaseUser();
    const store = useTNFStore();
    const { canUserProgress } = useModuleProgress();

    let modulePaths = ["module-1", "module-2", "module-3", "module-4"]

    if( user.value && !canUserProgress( to.name ) && modulePaths.includes( to.name ) ){
        return navigateTo('/dashboard');
    }
});