import { default as course_45guidance1BcDqOkmkmMeta } from "/opt/build/repo/pages/course-guidance.vue?macro=true";
import { default as dashboardB0S6bZ6pqpMeta } from "/opt/build/repo/pages/dashboard.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as module_451dnI7M8XOyIMeta } from "/opt/build/repo/pages/module-1.vue?macro=true";
import { default as module_452rSYo0sTyLQMeta } from "/opt/build/repo/pages/module-2.vue?macro=true";
import { default as module_453BQSyouLOWOMeta } from "/opt/build/repo/pages/module-3.vue?macro=true";
import { default as module_454Kco9aVsGy5Meta } from "/opt/build/repo/pages/module-4.vue?macro=true";
import { default as privacy_45policyQXTlhFuZ55Meta } from "/opt/build/repo/pages/privacy-policy.vue?macro=true";
import { default as reportskGhlBo6dOMeta } from "/opt/build/repo/pages/report.vue?macro=true";
import { default as resourcesu6YvzTZXHcMeta } from "/opt/build/repo/pages/resources.vue?macro=true";
export default [
  {
    name: course_45guidance1BcDqOkmkmMeta?.name ?? "course-guidance",
    path: course_45guidance1BcDqOkmkmMeta?.path ?? "/course-guidance",
    meta: course_45guidance1BcDqOkmkmMeta || {},
    alias: course_45guidance1BcDqOkmkmMeta?.alias || [],
    redirect: course_45guidance1BcDqOkmkmMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/course-guidance.vue").then(m => m.default || m)
  },
  {
    name: dashboardB0S6bZ6pqpMeta?.name ?? "dashboard",
    path: dashboardB0S6bZ6pqpMeta?.path ?? "/dashboard",
    meta: dashboardB0S6bZ6pqpMeta || {},
    alias: dashboardB0S6bZ6pqpMeta?.alias || [],
    redirect: dashboardB0S6bZ6pqpMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: module_451dnI7M8XOyIMeta?.name ?? "module-1",
    path: module_451dnI7M8XOyIMeta?.path ?? "/module-1",
    meta: module_451dnI7M8XOyIMeta || {},
    alias: module_451dnI7M8XOyIMeta?.alias || [],
    redirect: module_451dnI7M8XOyIMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/module-1.vue").then(m => m.default || m)
  },
  {
    name: module_452rSYo0sTyLQMeta?.name ?? "module-2",
    path: module_452rSYo0sTyLQMeta?.path ?? "/module-2",
    meta: module_452rSYo0sTyLQMeta || {},
    alias: module_452rSYo0sTyLQMeta?.alias || [],
    redirect: module_452rSYo0sTyLQMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/module-2.vue").then(m => m.default || m)
  },
  {
    name: module_453BQSyouLOWOMeta?.name ?? "module-3",
    path: module_453BQSyouLOWOMeta?.path ?? "/module-3",
    meta: module_453BQSyouLOWOMeta || {},
    alias: module_453BQSyouLOWOMeta?.alias || [],
    redirect: module_453BQSyouLOWOMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/module-3.vue").then(m => m.default || m)
  },
  {
    name: module_454Kco9aVsGy5Meta?.name ?? "module-4",
    path: module_454Kco9aVsGy5Meta?.path ?? "/module-4",
    meta: module_454Kco9aVsGy5Meta || {},
    alias: module_454Kco9aVsGy5Meta?.alias || [],
    redirect: module_454Kco9aVsGy5Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/module-4.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQXTlhFuZ55Meta?.name ?? "privacy-policy",
    path: privacy_45policyQXTlhFuZ55Meta?.path ?? "/privacy-policy",
    meta: privacy_45policyQXTlhFuZ55Meta || {},
    alias: privacy_45policyQXTlhFuZ55Meta?.alias || [],
    redirect: privacy_45policyQXTlhFuZ55Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: reportskGhlBo6dOMeta?.name ?? "report",
    path: reportskGhlBo6dOMeta?.path ?? "/report",
    meta: reportskGhlBo6dOMeta || {},
    alias: reportskGhlBo6dOMeta?.alias || [],
    redirect: reportskGhlBo6dOMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/report.vue").then(m => m.default || m)
  },
  {
    name: resourcesu6YvzTZXHcMeta?.name ?? "resources",
    path: resourcesu6YvzTZXHcMeta?.path ?? "/resources",
    meta: resourcesu6YvzTZXHcMeta || {},
    alias: resourcesu6YvzTZXHcMeta?.alias || [],
    redirect: resourcesu6YvzTZXHcMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/resources.vue").then(m => m.default || m)
  }
]