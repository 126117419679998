export default defineNuxtRouteMiddleware( async( to, from ) => {
    const user = useSupabaseUser();
    const store = useTNFStore();

    let emailCheck = false;

    if( user.value ) {
        let emailAddress = user.value.email.split('@').pop()

        if( emailAddress == '21six.com' || emailAddress == 'canyouthinkbeyond.com' ) {
            emailCheck = true;
        }
    }

    if( !user.value && to.path == "/report" || !emailCheck && to.path == "/report" ){
        return navigateTo('/')
    }
})