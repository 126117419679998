<template>
    <div class="tnf-allyship">
        <Loading v-if="loading"></Loading>
        <nuxt-layout>
            <nuxt-page />
        </nuxt-layout>
        <SVGMountain />
    </div>
</template>

<script setup>
    import { gsap } from "gsap";

    const { getUserProgress } = useUserProgress();
    const { isMobile } = useResponsive();
    const user = useSupabaseUser();

    const nuxtApp = useNuxtApp();
    const loading = ref(true);

    gsap.config({nullTargetWarn:false});

    useHead({
        bodyAttrs: {
            class: computed(() => {
                if ( isMobile.value == true ) return "is-mobile";
                return "";
            }),
        },
    });

    nuxtApp.hook("page:finish", () => {
        setTimeout(function(){
            if( loading.value ) {
                var tl = gsap.timeline();
                    tl.to(".loading-screen", { duration: 1, zIndex: 1, opacity: 0, onComplete: () => {
                        loading.value = false;
                        document.documentElement.classList.add("loaded");
                        document.body.classList.add("loaded");
                    }}, 0);
            }
        }, 500);
    });
    
    watch(() => user.value, async () => {
        await getUserProgress();
    });
</script>